import React, {useState} from 'react'
import {product} from '../components/Product'
import Overlay from '../components/Overlay'
const Wine = () => {
    const [openOverlay, setOpenOverlay] = useState(false);
    const [toggle, setToggle] = useState(0)
    return (
        <section>
            {/* Display wine */}
            <h1 className='flex justify-center font-Hurricane text-4xl md:text-6xl pt-4'>Our Wines</h1>
            <Overlay open={openOverlay} onClose={() => setOpenOverlay(false)} index={toggle}/>
            <div className='flex justify-center items-center grid grid-cols-4 gap-6 mt-6'>                
                {/*map the product file, when image clicked, if the image matches the id, set the toggle and execute command*/}
                {product.map((item, index)=>(
                    <div key={index} className="hover:scale-105 duration-300">
                        <div className={
                            openOverlay
                            ? 'hidden'
                            : 'flex flex-col justify-center'
                        }>
                            <p className='hidden md:flex justify-center'>{item.name}</p>
                            <div className='flex justify-center'>                                
                                <img src={item.image} alt='' className = 'shadow rounded-full w-1/2 h-auto align-middle border-none' 
                                onClick={() => {setOpenOverlay(true); setToggle(item.id)}}>
                                </img>
                            </div>
                        </div>
                    </div>
                ))}            
            </div>
        </section>
    );
};

export default Wine;
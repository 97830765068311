import React from 'react'
import {product} from '../components/Product'
const Overlay = ({open, onClose, index}) =>{
    if(!open) return null;
    return(
        <section onClick={onClose} className='absolute inset-0 top-0 left-0 w-full h-screen bg-black/75 pt-40 z-10'>
            <div className=''
                onClick={(e) => {
                e.stopPropagation();
                }}>
                <div className='flex flex-col justify-center items-center text-white'>
                    <p className=' text-5xl md:text-7xl font-Hurricane'>{product[index-1].name}</p>
                    <img src={product[index-1].image} alt='' className='w-[400px] h-[400px] mt-6 border rounded-lg'></img>
                    <p className='text-xl mt-6 mx-4 md:mx-20'>{product[index-1].detail}</p>    
                </div>
            </div>
        </section>
    );
    };

export default Overlay;
import React from 'react'
import {Link} from 'react-router-dom'
const Navbar = () => {
    return(
        <section>
            <div className='relative flex justify-center container w-full mt-4 bg-gradient-to-tr from-white to-oceanBlue'>
                <div className='flex items-center justify-between'>
                    <div className="space-x-6 md:space-x-10 text-3xl md:text-5xl">
                        <Link to="/"className="border-solid hover:text-white font-Hurricane">Home</Link>
                        <Link to="/Wine"className="hover:text-white font-Hurricane">Wine</Link>
                        <Link to="/About"className="hover:text-white font-Hurricane">About</Link>
                        <Link to="/Club"className="hover:text-white font-Hurricane">Club</Link>                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Navbar
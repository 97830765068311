import passion from '../Images/passion.jpg'
import krampus from '../Images/Krampus.jpg'
import firststab from '../Images/FirstStab.jpg'
import traveller from '../Images/Traveller.jpg'
import alluring from '../Images/alluring.jpg'
import companion  from '../Images/companion.jpg'
import threebird from '../Images/threebird.jpg'
import sweetheart from '../Images/sweetheart.jpg'

export const product = [
    {
        id:1,
        name: 'Passion',        
        image: passion,
        price:'$$',
        detail:'Passion was a Perfect start. We have a passion for living, each other, and for wine. We have put all of that into this bottle, featuring a passion fruit based sweet wine with finishing aromas of the tropics.',
    },
    {
        id:2,
        name: 'First Stab',        
        image: firststab,
        price:'$$',
        detail:'Our "First Stab" at producing wine. This first edition in the "Faces of Fear" series features an apple base with a tart and spice finish, highlighting the flavors of the Halloween season.',
    },
    {
        id:3,
        name: 'Krampus',        
        image: krampus,
        price:'$$',
        detail:'Naughty or Nice? Im not the one holding this bottle of wine. But since its too late to be goo now, enjoy! Hinting of the Christmas spirit, this Cranberry Riesling is not responsible for which of St. Nicks list youre on.  There is always hope for next year.',
    },
    {
        id:4,
        name: 'Sweet Heart',
        category: 'Wine',
        image: sweetheart,
        price:'$$',
        detail:'To be loved by another. To be loved by all.  To be genuine.  Sweet Heart is striving to be all the above.  This Black Cherry and Sugared Plum wine boasts large hints of vanilla. Whether it be a date night or a day trip to the beach, enjoy it with your Sweet Heart.',
    },
    {
        id:5,
        name: 'Alluring',        
        image: alluring,
        price:'$$',
        detail:'',
    },
    {
        id:6,
        name: 'Companion',        
        image: companion,
        price:'$$',
        detail:'',
    },
    {
        id:7,
        name: 'Three Birds',        
        image: threebird,
        price:'$$',
        detail:'',
    },
    {
        id:8,
        name: 'Traveller',        
        image: traveller,
        price:'$$',
        detail:'A coconut can travel up to 100 days on the oceans tide and still germinate. Because if their water resistance, coconuts are able to stay afloat and can be found all over the Pacific and Indian Ocean. You could say the coconut is a great Traveller. We at Coastal Visions second this mindset.  Travel often and far',
    }

];
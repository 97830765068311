import React from 'react'
import Love from '../Images/love.jpg'
import QR from '../Images/qrcode.png'
const About = () => {
    return (
        <section>
            <div className='flex justify-center pt-2'>
                <img className='w-3/4 border rounded-lg'src={Love} alt=''></img>
            </div>
            <div className='flex flex-col items-center pt-4'>                           
                <div className='flex flex-col items-center pt-4'>
                    <h1 className='shadow-2xl font-Hurricane text-4xl md:text-6xl'>Our Story</h1>
                    <p className='lg:mx-12 pt-2'>In the spring of 2022, Sue Poupard took her husband, Shawn, on a surprise drive. 
                    Where they ended up was just the beginning. After leaving a local home brew store, they knew their dream,
                    and idea of creating wines was more obtainable than ever. Together, they strive daily to continue to grow 
                    their hobby and vision of what the future may bring. Join us in our "Coastal Visions."</p>
                </div>
            </div>
            <div className='flex justify-center pt-10'>
                <div className='relative w-[150px] h-[150px] md:w-[300px] md:h-[300px] bg-black'>
                    <img className='absolute'src={QR} alt=''></img>
                </div>
            </div>
        </section>
    )
}

export default About;
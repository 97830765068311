
import Logo from './Images/blklogo.png'
import Navbar from './components/Navbar'
import Mission from './components/Mission'
import Links from './components/Links'
import Wave from './Images/wave.png'
import About from './components/About'
import Wine from './components/Wine'
import Club from './components/Club'
import { Route,Routes} from 'react-router-dom'

function App() {
  return (
    <section>      
      <div className='hidden md:flex w-full h-screen bg-black' ></div>
      <div className="md:flex absolute md:top-20 md:left-20 md:right-20 justify-center">          
        <div className='bg-white border rounded-lg md:w-5/6'>
                           
          < div className='flex flex-col'>
            <img className='object-contain object-scale-down h-40 w-50' src={Logo} alt=''></img>
            <Navbar/>
            <Routes>
              <Route path="/" element={<Mission/>}></Route>
              <Route path="/About" element={<About/>}></Route>
              <Route path="/Wine" element={<Wine/>}></Route>
              <Route path="/Club" element={<Club/>}></Route>
            </Routes>  
            <div class='relative'>
                <img className='w-full h-full'src={Wave}alt=''></img>               
                <div className='md:absolute md:bottom-10 w-full flex justify-center'>
                  <Links/>
                </div>
            </div>
            
          </div>                      
        </div>
      </div>
      <div className='hidden md:flex w-full h-screen bg-black'></div>
    </section>    
  );
}

export default App;

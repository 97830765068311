import React from 'react'
import bottles from '../Images/wine4.jpg'
import QR from '../Images/qrcode.png'
const Club = () => {
    return (
        <section>
            <div className='flex justify-center pt-2'>
                <img className='w-3/4 border rounded-lg'src={bottles} alt=''></img>
            </div>
            <div className='flex flex-col items-center pt-4'>                           
                <div className='flex flex-col justify-center pt-4'>
                    <div className='flex justify-center'>
                        <h1 className='shadow-2xl font-Hurricane text-4xl md:text-6xl'>High Tide Club</h1>
                    </div>
                    <p className='lg:mx-12 pt-2'>-As a member of the High Tide Club, you will get exclusive small batch trial 
                    releases and have the opportunity to provide feedback on which wines should be produces in larger batches.</p>
                    <p className='lg:mx-12 pt-2'>-Members get promotional items as released</p>
                    <p className='lg:mx-12 pt-2'>-Members also get an exclusive prerelease to all wines, in order to allow a 
                    window of opportunity to get additional wines before general wine friends and family. </p>
                    <p className='lg:mx-12 pt-2'>- Please contact us through email, messenger, or text on how to become a High Tide Member
                
                    </p>
                </div>
            </div>
            <div className='flex justify-center pt-10'>
                <div className='relative w-[150px] h-[150px] md:w-[300px] md:h-[300px] bg-black'>
                    <img className='absolute'src={QR} alt=''></img>
                </div>
            </div>
        </section>
    )
}

export default Club;
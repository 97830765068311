import Beach from '../Images/beach.png'
import React, {useState} from 'react'
import Reviews from '../components/Reviews'

const Mission = () => {
    const [openReview, setOpenReview] = useState(false);

    return (
        <section>
            <Reviews open={openReview} onClose={() => setOpenReview(false)}/>   
            <div className='p-2'>
                <img className='object-fill w-full border rounded-lg'src={Beach} alt=''></img>
            </div>
            <div className={
                openReview
                ? 'hidden'
                :'flex flex-col items-center pt-4'}>                           
                <div className='flex flex-col items-center pt-4'>
                    <h1 className='shadow-2xl font-Hurricane text-4xl md:text-6xl'>Our Mission</h1>
                    <p className='lg:mx-12 pt-2 mb-20'>Everyone dreams of that feeling of their toes in the sand, a refreshing breeze off the bright, clear turquoise water,
                    and everything just being good and carefree. With our wines, we strive for that refreshing and calming sensation.  
                    Using locally sourced fruits and ingredients, we support local small businesses,  farms, and families throughout Michigan and Ohio.  
                    Join us in our "Vision."</p>
                </div>
                <button className='text-2xl md:text-3xl flex justify-center hover:scale-105 duration-300'
                    onClick={ () => setOpenReview(true)}>Reviews
                </button>
            </div>
        </section>
    )
}

export default Mission;
import React from 'react'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFacebook,faTwitter,faInstagram,} from '@fortawesome/free-brands-svg-icons'


const Links = () => {
    
    return (
        <section>
            <div className='flex flex-col justify-center'>
                <div className='flex flex-col items-center'>
                    <p>shawnp@coastalvisionswine.com</p>
                    <p>734-693-0687</p>
                </div>
                <p className='flex justify-center'>_______________________</p>
                <div className="flex items-center justify-center space-x-12">
                    <a href="https://www.facebook.com/CoastalVisionsWine">
                        <FontAwesomeIcon icon={faFacebook} className="w-10 h-10"/>                
                    </a>
                    <a href="https://facebook.com">
                        <FontAwesomeIcon icon={faTwitter} className="w-10 h-10"/>                
                    </a>
                    <a href="https://www.instagram.com/Coastal_Visions_22/">
                        <FontAwesomeIcon icon={faInstagram} className="w-10 h-10"/>                
                    </a>
                    
                </div>
            </div>
        </section>
    )
}

export default Links;
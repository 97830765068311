import React from 'react'
const Reviews = ({open, onClose}) => {
    if(!open) return null;
    return (
        <section onClick={onClose} className='absolute top-0 left-0 w-full h-full bg-black/75 z-10'>
            <div className=''
                onClick={(e) => {
                e.stopPropagation();
                }}>
                <div className='flex flex-col justify-center items-center text-white pt-30'>
                    <p className=' text-5xl md:text-7xl font-Hurricane mt-10'>FB Review</p>
                    <p className='text-xl mt-6'>More Review information</p>
                    <div className=" w-full md:w-1/2 px-6">
                        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fbrett.fennelly%2Fposts%2Fpfbid02pMjpev4Dbvc4Vb2QUynWFJLWE6UtHVxGeeAodKHJZLFvRUVrfz8g8wV3Gj4HXPnul&show_text=true&width=500" 
                            title='Review' className='border rounded-lg mt-5 w-full bg-white'></iframe>
                        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid036mBFeZJmZRZmPdai1jMedicwqWNZgBNtUminiPFeJiomyCEHTsRB6fzbMmHgbLVUl%26id%3D100085053752631&show_text=true&width=500"
                            title='Review2'className='border rounded-lg mt-5 w-full object-cover bg-white'></iframe>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default Reviews;